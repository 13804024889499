<template>
    <div>
        <!-- Authors Table -->
        <a-row :gutter="24" type="flex">

            <!-- Authors Table Column -->
            <a-col :span="24" class="mb-24">

                <a-card :bordered="true" class="header-solid h-full" style="min-height: 400px" v-if="!loading" :loading="loading">
                   <a-form layout='vertical' :form="form" @submit="submitForm">
                        <a-form-item>
                          <div class="ant-page-header-heading-title">Basic Info</div>

                        </a-form-item>
                            <a-form-item label='Album Title'>
                                <a-input
                                    placeholder="Input a descriptive album name"
                                    v-decorator="[

              'title',
                        {
                          rules: [{ required: true, message: 'Album Title is required' }],
                        initialValue:selectedItem.title
              }
            ]"
                                />
                            </a-form-item>
                     <a-form-item label='Description'>
                       <a-input
                           :auto-size="{ minRows: 4, maxRows: 8 }"
                           type='textarea'
                           :maxLength="1500"
                           placeholder="Write short description here"
                           v-decorator="['description',{
                                                       rules: [{ required: true, message: 'Album Description is required' }],
                initialValue:Object.keys(selectedItem).length!=0?selectedItem.description:'',
              }]"
                       />
                     </a-form-item>
                     <a-row :gutter="24" type="flex">
                       <a-col :span="12" >
                          <a-form-item label='Collection'>
                            <a-select
                                show-search
                                placeholder="Select a collection"
                                option-filter-prop="children"
                                :filter-option="filterOption"
                                :loading="loadingCategories"
                                v-decorator="[
          'collectionId',
          { rules: [{ required: true, message: 'Please select a collection' }],
           initialValue:selectedItem.collectionId
           },
        ]"
                            >
                              <a-select-option :value="cat._id" v-for="(cat,index) in getAllCategories" :key="index">
                                <span style="margin-right: 5px;align-items: center" role="img" >
                <a-avatar shape="square" :src="url+cat.image"></a-avatar>
      </span>
                                {{cat.title}}
                              </a-select-option>
                            </a-select>
                            </a-form-item>
                       </a-col>
                         <a-col :span="12" >
<a-form-item label="Date">
  <a-date-picker style="width: 100%" v-decorator="[
          'date',
          { rules: [{ required: true, message: 'Please select a date' }],
           initialValue:moment(selectedItem.date)
           },]"/>

</a-form-item>
                         </a-col>
                     </a-row>

                       <a-row :gutter="24" type="flex">
                         <a-col :span="12" >
                           <a-form-item label='Street Address'>
                             <a-input
                                 placeholder="Input a descriptive address"
                                 v-decorator="[

              'streetAddress',
                        { rules: [{ required: true, message: 'Street Address is required' }] ,
                        initialValue:selectedItem&&selectedItem.address?selectedItem.address.streetAddress:'',
              }
            ]"
                             />
                           </a-form-item>
                         </a-col>

                         <a-col :span="12" >
                           <a-form-item label='Post Code'>
                             <a-select
                                 show-search
                                 placeholder="Input Post Code"
                                 :default-active-first-option="false"
                                 :show-arrow="false"
                                 :filter-option="false"
                                 :not-found-content="fetching ? undefined : null"
                                 @search="fetchPostCode"
                                 @change="handleChange"
                                 v-decorator="[

              'post_code_with_address',
                        { rules: [{ required: true, message: 'Post Code is required' }],
                       initialValue:selectedItem&&post_code_with_address?post_code_with_address:undefined
                         },

            ]"
                             >
<!--                               <a-select-option value="">-->
<!--                                 Input Post Code-->
                                 <a-spin v-if="fetching" slot="notFoundContent" size="small" />
<!--                               </a-select-option>-->
                               <a-select-option v-for="d in data" :key="d.value">
                                 {{ d.text }}
                               </a-select-option>
                             </a-select>
                           </a-form-item>
                         </a-col>
                       </a-row>
                     <a-form-item label='Tags'>
                       <a-select dropdownClassName="tags-dropdown" mode="tags" style="width: 100%" placeholder="Type in your tag name and hit Enter"
                                 v-decorator="[
          'tags',
          {
                       initialValue:selectedItem.tags

          }
        ]"
                       >

                       </a-select>
                     </a-form-item>
                     <a-form-item  label='Related Event'>
                     <div id="events">
                            <a-select
                                show-search
                                placeholder="Select an event"
                                option-filter-prop="children"
                                :show-arrow="false"
                                :filter-option="false"
                                allowClear
                                showSearch
                                @search="allEvents"
                                :loading="loadingEvents"
                                v-decorator="[
          'event',
          {
           initialValue:selectedItem&&selectedItem.event?selectedItem.event:undefined
           },
        ]"
                            >
                              <a-spin v-if="loadingEvents" slot="notFoundContent" size="small" />

<!--                              <a-select-option value="">-->
<!--                                Select an event-->
<!--                              </a-select-option>-->
                              <a-select-option :value="item._id" v-for="(item,index) in events" :key="index">
                                <h6 class="avatar-info">
                                  <span style="color:rgba(0, 0, 0, 0.45);font-weight: 600">#{{item.eventNumber}}</span>
                                  {{item.title}} <a-tag v-if="item"  :class="{'ant-tag-success':item.status=='published'||item.status=='Published','ant-tag-primary':item.status=='draft'||item.status=='Draft','ant-tag-danger':item.status=='deleted'||item.status=='Deleted','ant-tag-warning':item.status=='Inactive'||item.status=='inactive'}" style="margin-left: 10px">
                                  {{ item.status|capitalizeFirstLetter }}
                                </a-tag>
                                  <a-spin v-if="index==0&&loadingEvents" size="small" />
                                </h6>
<!--                                <div style="display: flex">-->
<!--                                <span style="margin:0 5px 0 0">-->
<!--                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>-->
<!--                </span>-->
<!--                                <div v-if="item.dateType=='single'">-->
<!--                                  <p>{{item.locationType=='physical'?(item.singleSchedule.post_code + ', ' + item.singleSchedule.district + ', ' + item.singleSchedule.country):(item.locationType=='online'?'Online':'Not Announced')-->
<!--                                    }}</p>-->

<!--                                </div>-->
<!--                                  <div v-if="item.dateType!='single'" >-->
<!--                  <a-tag size="small" >-->
<!--                    Multiple Schedules-->
<!--                  </a-tag>-->
<!--               </div>-->

<!--                                </div>-->
                              </a-select-option>
                            </a-select>
                     </div>
                            </a-form-item>






                     <a-form-item :wrapper-col="{ span: 24 }" :style="{ textAlign: 'right' }">
                       <a-space size="small">
                       <a-button type="primary" html-type="submit" :loading="confirmLoading">
                         {{ footerBtnTitle }}
                       </a-button>
                         <router-link :to="{name:'Image Albums'}">
                         <a-button type="danger" :loading="confirmLoading">
                           Cancel
                         </a-button>
                         </router-link>
                       </a-space>
                     </a-form-item>

                        </a-form>


                </a-card>

              <a-card :bordered="true" class="header-solid h-full" style="min-height: 400px" v-else>
                <a-row :gutter="[24, 24]">
                  <a-col :span="24" >
                    <a-skeleton active />
                    <a-skeleton active />
                  </a-col>
                  <a-col :span="12" >
                    <a-skeleton active />
                    <a-skeleton active />
                  </a-col>
                  <a-col :span="12" >
                    <a-skeleton active />
                    <a-skeleton active />
                  </a-col>
                  <a-col :span="24" >
                    <a-skeleton active />
                    <a-skeleton active />
                  </a-col>
                </a-row>
              </a-card>
            </a-col>

        </a-row>
    </div>
</template>

<script>

import {mapGetters} from 'vuex'
import debounce from 'lodash/debounce';
import moment from 'moment';

export default {
    data() {
      this.fetchEvents = debounce(this.fetchEvents, 600);
      this.lastFetchId = 0;
      this.fetchPostCode = debounce(this.fetchPostCode, 600);
      return {
        moment,
          loadingCategories:false,
        loadingEvents:false,
        events:[],
          data: [],
          value: [],
          fetching: false,
        lng:'',
        lat:'',
        district:'',
        country:'',
        ward:'',
        ccg:'',
        locationType:'physical',
        post_code_with_address:'',
        date:'',
        id:'',
            url: process.env.VUE_APP_SERVER_URL,

            loading: false,
            fileList: [],
            confirmLoading: false,
            form: this.$form.createForm(this),
        editMode:false

        };
    },
    computed: {
      footerBtnTitle() {
            return this.editMode ? 'Update Album' : 'Create Album'
        },
      getAllCategories(){
          return this.$store.getters.getAllCategories
      },
      selectedItem(){
          return this.$store.getters.selectedPost
      }
        },
    beforeMount() {
        this.allFetchCategories();
        this.allEvents();
        if (this.$route.params.id){
          this.editMode=true
        this.postInfo()
        }
    },

    methods: {
      fetchPostCode(value) {
        if (value){
          this.lastFetchId += 1;
          const fetchId = this.lastFetchId;
          this.data = [];
          this.fetching = true;
          fetch('https://api.postcodes.io/postcodes/' + value + '/autocomplete')
              .then(response => response.json())
              .then(body => {
                if (fetchId !== this.lastFetchId) {
                  // for fetch callback order
                  return;
                }
                if (!body.result) {
                  this.data = [{text:'No Post Code Found',value:''}];
                  this.fetching = false;
                  return;
                }
                const data = body.result.map(item => ({
                  text: item,
                  value: item,
                }));
                this.data = data;
                this.fetching = false;
              });
        }
        else {
          this.lng=this.lat=this.district=this.country=this.ward=this.ccg=this.post_code=''
        }
      },
      handleChange(value) {
        fetch('https://api.postcodes.io/postcodes/' + value)
            .then(response => response.json())
            .then(body => {
              this.lng=body.result.longitude
              this.lat=body.result.latitude
              this.country=body.result.country
              this.district=body.result.admin_district
              this.ward=body.result.admin_ward
              this.ccg=body.result.ccg
              this.post_code=value
              const addr=value+', '+this.district+', '+this.country
              this.form.setFieldsValue({
                post_code_with_address: addr,
              });
              Object.assign(this, {
                data: [],
                fetching: false,
              });
            })

      },
      postInfo(){
        this.loading=true
        this.$store.dispatch("FETCH_POST",{id:this.$route.params.id})
            .then((res)=>{
              let data =res.data
              this.id=data._id
              this.lng=data.address.lng
              this.lat=data.address.lat
              this.country=data.address.country
              this.district=data.address.district
              this.ward=data.address.ward
              this.ccg=data.address.ccg
              this.post_code=data.address.post_code
              this.post_code_with_address = this.post_code + ', ' + this.district + ', ' + this.country
            })
        .finally(()=>{
          this.loading=false
        })
      },
        submitForm(e) {
            e.preventDefault();
          if (this.confirmLoading)
            return
            this.form.validateFields((err, values) => {
                if (!err) {
                  values.id=this.id
                  values.type='album'
                  values.lng=this.lng
                  values.lat=this.lat
                  values.country=this.country
                  values.district=this.district
                  values.ward=this.ward
                  values.ccg=this.ccg
                  values.post_code=this.post_code
                  this.confirmLoading = true
                    if (this.editMode){
                        this.$store.dispatch("UPDATE_ALBUM_POST",{id:this.id,form:values})
                            .then((res) => {
                                this.$message.success(res.data.message);
                                this.$emit('changeTab','2')
                            })
                            .catch((err) => {
                                // this.$message.error(err.response.data.message);
                            })
                            .finally(() => {
                                this.confirmLoading = false
                            });
                    }
                    else {
                        this.$store.dispatch("CREATE_ALBUM_POST", values)
                            .then((res) => {
                                this.form.resetFields()
                                this.$message.success(res.data.message);
                                this.$router.push({name:'Edit Album | Images',params:{id:res.data.id}})
                            })
                            .finally(() => {
                                this.confirmLoading = false
                            });

                    }

                }
            });
        },
     
      allFetchCategories(){
          this.loadingCategories=true
        this.$store.dispatch("FETCH_ALL_COLLECTIONS")
        .then(()=>{
          this.loadingCategories=false
        })
      },
      fetchEvents(search){
        this.$store.dispatch("ALL_EVENTS",{params:{results:7,page:1,search:search,select_options:true}})
        .then((res)=>{
          this.events=res.data.results
          this.loadingEvents=false
        })
      },
      filterOption(input, option) {
        return (
            option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
      },
      allEvents(search) {
        this.loadingEvents=true
        this.fetchEvents(search)
      },
     

    },
  beforeCreate() {
    if (!this.$route.params.id)
      this.$store.commit('RESET_SELECTED_POST')
  },
};
</script>
<style>
.ant-calendar-time-picker-column-3 .ant-calendar-time-picker-select{
  width: 50%;
}
.ant-calendar-time-picker-select:last-child{
  display: none;
}
</style>
